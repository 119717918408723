import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import Styled404 from "../components/styles/404"
import ButtonStyled from "../components/styles/ButtonStyles"
import image from "../images/404img.svg"

const Page404 = ({ data, pageContext }) => {
  return (
    <Layout pageContext={pageContext}>
      <Styled404 className="container" colorHeading="rgb(208, 0, 96)">
        <div className="notfound">
          <div className="notfound__row">
            <div className="notfound__col--3">
              <h1 className="notfound__heading">
                <span>Cześć,</span> <span>miło Cię widzieć</span>
              </h1>
              <div className="notfound__content">
                <div className="notfound__text">
                  <p>
                    niestety trafiłeś na miejsce bez połączenia, co oznacza, że
                    ​​strona, której szukasz, nie istnieje.
                  </p>
                </div>
                <div className="notfound__text">
                  <p>Szukasz, którejś z naszych usług? Przejdź na:</p>
                  <ul>
                    <li>
                      <Link to="/data-programmatic-consulting/">
                        Data & Programmatic Consulting
                      </Link>
                    </li>
                    <li>
                      <Link to="/moc-big-data-w-kampaniach-content-marketingowych/">
                        Content Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="/mozliwosci-big-data-w-performance-marketingu/">
                        Performance Marketing
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="notfound__text">
                  <p>Lub wróć na stronę główną:</p>
                </div>
                <ButtonStyled dark={true} left={true}>
                  <Link to="/">Przenieś mnie na stronę główną</Link>
                </ButtonStyled>
              </div>
            </div>
            <div className="notfound__col--2">
              <div className="notfound__img">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Styled404>
    </Layout>
  )
}

export default Page404
